import React, { useState } from "react";
import styles from "./KnowYourBooth.module.css";
import votelogo from "../../assets/votesign.png";
import axios from "axios"; // Import axios

const KnowYourBooth = () => {
  const [inputValue, setInputValue] = useState("");
  const [isValid, setIsValid] = useState(null);
  const [boothData, setBoothData] = useState(null); // Store fetched data
  const [error, setError] = useState(""); // Store any error message

  const handleSearch = async () => {
    if (!inputValue) {
      setError("Please enter a valid HRMS ID or Employee ID.");
      return;
    }

    try {
      // Convert input value to uppercase before sending to the backend
      const uppercaseInputValue = inputValue.toUpperCase();

      // Use axios to make the GET request to your search endpoint
      const response = await axios.get(
        `https://nwreu-backend.vercel.app/knowyourbooth/search`,
        {
          params: { hrmsId: uppercaseInputValue, empId: uppercaseInputValue }, // Send either hrmsId or empId
        }
      );

      if (response.data) {
        setBoothData(response.data); // Update state with fetched data
        setIsValid(true); // Indicate the data is valid
        setError(""); // Reset error message
      } else {
        setIsValid(false); // Indicate invalid ID
        setBoothData(null); // Clear previous data
        setError("No data found for the given ID.");
      }
    } catch (err) {
      setIsValid(false);
      setBoothData(null);
      setError("Error fetching data from the server. Please try again.");
    }
  };

  return (
    <div className={styles.container}>
      {/* Header Section */}
      <div className={styles.header}>
        <img src={votelogo} alt="NWREU Logo" className={styles.logo} />
        <h1 className={styles.title}>North Western Railway Employees Union</h1>
      </div>

      {/* Vote for Flag */}
      <div className={styles.voteText}>
        <h2>
          Vote for <span className={styles.highlight}>NWREU</span> Vote for{" "}
          <span className={styles.highlight}>Flag</span>
        </h2>
      </div>

      {/* Input field */}
      <div className={styles.searchField}>
        <h4 className={styles.idins}>Enter either HRMS ID or Employee ID:</h4>
        <h4 className={styles.idins}>अपनी HRMS या एम्प्लाई ID दर्ज करें</h4>

        <input
          type="text"
          className={styles.input}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Enter HRMS ID or Employee ID"
        />
        <button onClick={handleSearch} className={styles.searchBtn}>
          Know your Booth
        </button>
      </div>

      {/* Show table or error message */}
      {isValid === true && boothData && (
        <>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.cell}>HRMS ID:</div>
              <div className={styles.cell}>{boothData.hrmsId}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.cell}>Employee ID:</div>
              <div className={styles.cell}>{boothData.empId}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.cell}>Name of Employee:</div>
              <div className={styles.cellName}>{boothData.name}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.cell}>Designation:</div>
              <div className={styles.cell}>{boothData.designation}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.cell}>Working Under:</div>
              <div className={styles.cell}>{boothData.workingUnder}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.cell}>Station:</div>
              <div className={styles.cell}>{boothData.station}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.cell}>Booth Number:</div>
              <div className={styles.cell}>{boothData.boothNumber}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.cell}>Booth Name:</div>
              <div className={styles.cellName}>{boothData.boothName}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.cell}>Voter List Serial Number:</div>
              <div className={styles.cell}>{boothData.voterListNumber}</div>
            </div>
          </div>

          <div className={styles.voteBottom}>
            <h2>
              Vote for <span className={styles.highlight}>NWREU</span> Vote for{" "}
              <span className={styles.highlight}>Flag</span>
            </h2>
          </div>
        </>
      )}

      {isValid === false && <p className={styles.errorMessage}>{error}</p>}

      {error && !isValid && <p className={styles.errorMessage}>{error}</p>}
    </div>
  );
};

export default KnowYourBooth;
